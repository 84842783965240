<template>
  <div class="user-info">
    <div class="user-info__title">Мой профиль</div>
    <div class="user-info__photo-wrapper">
      <img v-if="!userAvatar" src="@/assets/img/user/user-no-photo.svg" alt="photo">
      <img class="user-info__photo" v-else :src="userAvatar" alt="photo">
    </div>

    <div class="user-info__name">{{ user.fullName }}</div>

    <div class="user-info__detalis">
      <div>{{ user.location }}</div>
      <div v-if="user.location && numberYears" class="user-info__detalis-delimiter"/>
      <div>{{ numberYears }}</div>
    </div>

    <div class="user-info__edit-btn" @click="$router.push('/settings')">Редактировать профиль</div>
  </div>
</template>

<script>
import {userService} from "../../user.service";
import {mapGetters} from "vuex";

export default {
  name: "UserInfo",
  data() {
    return {
      user: {
        email: null,
        fullName: null,
        birthday: null,
      }
    }
  },
  computed: {
    ...mapGetters(['userAvatar']),
    numberYears() {
      if (this.user.birthday) {
        let dateNow = new Date().getFullYear()
        const birthday = new Date(this.user.birthday).getFullYear()

        const monthNow = new Date().getMonth() + 1
        const month = new Date(this.user.birthday).getMonth() + 1

        const dayNow = new Date().getDate()
        const day = new Date(this.user.birthday).getDate()

        if(monthNow < month)
          --dateNow
        else if(monthNow === month && dayNow < day)
          --dateNow

        const year = dateNow - birthday
        let title = ''
        if (year === 1)
          title = `${year} год`
        else if (year < 5 && year > 1)
          title = `${year} года`
        else if (year >= 5 && year <= 20)
          title = `${year} лет`
        else if (year >= 21) {
          let str = year.toString()
          if (Number(str[str.length - 1]) >= 5 || Number(str[str.length - 1]) === 0)
            title = `${year} лет`
          else if (Number(str[str.length - 1]) === 1)
            title = `${year} год`
          else if (Number(str[str.length - 1]) > 1 || Number(str[str.length - 1]) <= 4)
            title = `${year} года`
        }

        return title
      } else {
        return ''
      }
    }
  },
   mounted() {
    userService.getUserInfo().then((data)=>{
      this.user = data
    })
    userService.getAvatar().then(()=>{
      this.avatar = this.userAvatar
    })
  },
}
</script>

<style lang="scss" scoped>
.user-info {
  display: flex;
  align-items: center;
  flex-direction: column;

  &__title {
    font-family: Raleway;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: left;

    margin-bottom: 32px;

    @media screen and (max-width: 980px) {
      display: none;
    }
  }

  &__photo-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 130px;
    height: 130px;

    margin-bottom: 24px;

    border: 1px solid #1454F2;
    border-radius: 100%;
    box-sizing: border-box;

    transition: 0.2s;
    @media screen and (max-width: 600px) {
      width: 110px;
      height: 110px;
    }
    @media screen and (max-width: 500px) {
      width: 100px;
      height: 100px;
    }
  }

  &__photo {
    width: 100px;
    height: 100px;

    object-fit: cover;

    border-radius: 100%;

    transition: 0.2s;
    @media screen and (max-width: 600px) {
      width: 90px;
      height: 90px;
    }
    @media screen and (max-width: 500px) {
      width: 80px;
      height: 80px;
    }
  }

  &__name {
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }

  &__detalis {
    display: flex;
    align-items: center;

    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;

    color: #121212;
  }

  &__detalis-delimiter {
    content: '';

    width: 4px;
    height: 4px;

    background: #121212;
    border-radius: 100%;
    margin: 0 10px;
  }

  &__edit-btn {
    display: flex;
    justify-content: center;
    align-items: center;

    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;

    color: #000000;

    width: 270px;
    height: 52px;

    margin-top: 45px;

    background: #FFFFFF;

    border: 2px solid #1454F2;
    box-sizing: border-box;
    border-radius: 41px;

    cursor: pointer;
    transition: 0.2s;

    &:hover {
      background: #1454F2;
      color: #FFFFFF;
    }

    @media screen and (max-width: 500px) {
      font-size: 12px;

      width: 208px;
      height: 44px;
    }
  }
}

@media screen and (max-width: 980px) {
  .user-info__edit-btn{
    margin-top: 10px;
    margin-bottom: 20px;
    transition: 0.2s;
  }
}
</style>
