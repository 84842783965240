<template>
  <div class="user-courses-group">
    <div class="user-courses-group__header">
      <div class="user-courses-group__title">Мои курсы</div>
      <div class="user-courses-group__header-delimiter"/>
      <div class="user-courses-group__title">{{ countCourses }}</div>
    </div>
    <div class="user-courses-group__courses"
         v-for="(category,index) in categories" :key="index">

      <div class="user-courses-group__category">{{ category.name }}</div>

      <div class="user-courses-group__course"
           v-for="(item,index) in myCourses.filter(course => course.categories.find(item => item === category.id))"
           :key="index">

        <transition appear name="list">
          <user-course :course="item"/>
        </transition>
      </div>
    </div>

    <div v-if="isCourseWithoutCategory" class="user-courses-group__courses-no-category">
      <div class="user-courses-group__category">Курсы без направлений</div>

      <div class="user-courses-group__course"
           v-for="(item,index) in myCourses.filter(course => course.categories.length === 0)"
           :key="index">

        <transition appear name="list">
          <user-course :course="item"/>
        </transition>
      </div>

    </div>

  </div>
</template>

<script>

import UserCourse from "./UserCourse";
import {mapGetters} from "vuex";

export default {
  name: "UserCoursesGroup",
  components: {
    UserCourse
  },
  computed: {
    ...mapGetters(['getCourses', 'getCategories', 'myCourses']),
    countCourses() {
      return this.myCourses.length
    },
    allCategories() {
      return this.$store.state.courses.categories
    },
    myCourses() {
      return  this.$store.state.myCourses.courses
    },
    categories() {
      const categoriesIds = []
      this.myCourses.forEach(course => {
        course.categories.forEach(item =>{
          if(!categoriesIds.find(id => id === item))
            categoriesIds.push(item)
        })
      })

      return categoriesIds.map(categoryId => ({
        ...this.allCategories.find(category => category.id === categoryId)
      }))
    },

    isCourseWithoutCategory() {
      return this.myCourses.filter(course => course.categories.length === 0).length
    }
  },
}
</script>

<style lang="scss" scoped>
.user-courses-group {
  margin-top: 12px;
  margin-left: 100px;

  @media screen and (max-width: 980px) {
    margin-left: 0px;
    transition: 0.2s;
  }

  &__header {
    display: flex;
    align-items: center;
  }

  &__title {
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #121212;
  }

  &__header-delimiter {
    content: '';
    width: 4px;
    height: 4px;

    margin: 0px 10px;
    border-radius: 100%;
    background: #000000;
  }

  &__courses {
    display: flex;
    flex-direction: column;

    margin-top: 20px;

    .user-courses-group__course:last-child {
      .user-course-wrapper {
        margin-bottom: 0px;
      }
    }
  }

  &__courses-no-category {
    margin-top: 26px;

    .user-courses-group__course:last-child {
      .user-course-wrapper {
        margin-bottom: 0px;
      }
    }
  }

  &__category {
    font-family: Raleway;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    color: #121212;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-word;
    overflow: hidden;

    margin-bottom: 10px;

    @media screen and (max-width: 500px) {
      font-size: 16px;
      line-height: 24px;
      transition: 0.2s;
    }
  }
}
</style>