<template>
<div class="user-courses-wrapper">
  <user-courses-group/>
</div>
</template>

<script>

import UserCoursesGroup from "./UserCoursesGroup";

export default {
  name: "UserCourses",
  components:{
    UserCoursesGroup
  }
}
</script>

<style lang="scss" scoped>
.user-courses-wrapper{
  width: 100%;
}
</style>