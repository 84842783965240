<template>
  <div class="profile-wrapper">
    <user-info/>
    <user-courses v-if="isMyCourses"/>
    <user-courses-empty v-else-if="loading"/>
    <base-preloader :height="'auto'" v-else/>
  </div>
</template>

<script>
import UserInfo from "./components/UserInfo";
import UserCoursesEmpty from "./components/UserCoursesEmpty";
import UserCourses from "./components/UserCourses";
import BasePreloader from "@/components/BasePreloader";
import {mapGetters} from "vuex";

export default {
  name: "Profile",
  components: {
    UserInfo,
    UserCoursesEmpty,
    UserCourses,
    BasePreloader
  },
  computed: {
    ...mapGetters(['myCourses']),

    isMyCourses() {
      return this.myCourses.length !== 0;
    },
    loading() {
      return this.$store.state.myCourses.init
    }
  }
}
</script>

<style lang="scss" scoped>
.profile-wrapper {
  display: flex;
  width: 100%;
  margin-top: 20px;

  transition: 0.2s;

  &__preloader {
    width: 100%;
    height: calc(100vh - 300px);
  }

}

@media screen and (max-width: 980px) {
  .profile-wrapper {
    flex-direction: column;
    align-items: center;

    padding: 0px 24px;
    box-sizing: border-box;
    transition: 0.2s;
  }
}
</style>
