<template>
  <div class="user-course-empty">
    <div class="user-course-empty__title">У вас еще нет курсов</div>
    <img src="@/assets/img/user/no-courses.svg" alt="empty">
    <router-link to="/courses" class="user-course-empty__btn-select-course">Выберите курс</router-link>
  </div>
</template>

<script>
export default {
  name: "UserCoursesEmpty"
}
</script>

<style lang="scss" scoped>
.user-course-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;

  min-height: 680px;
  background: rgba(20, 84, 242, 0.05);
  border-radius: 20px;

  margin-left: 100px;
  margin-top: 72px;

  &__title {
    font-family: Raleway;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;

    margin-bottom: 37px;
  }

  &__btn-select-course {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 257px;
    height: 64px;

    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;

    text-decoration: none;
    color: #FFFFFF;

    margin-top: 85px;

    border-radius: 50px;
    background: #1454F2;

    cursor: pointer;

    &:hover {
      background-color: #4072ef;
    }
  }
}

@media screen and (max-width: 980px) {
  .user-course-empty {
    margin-left: 0px;
    transition: 0.2s;
  }
}
</style>