<template>
  <div class="user-course-wrapper">
    <router-link :to="`/course-info/${course.id}/program`" class="user-course-wrapper__link" >
      <div class="user-course-wrapper__course">
        <div class="user-course-wrapper__course-logo"
             :style="{background: course.color, borderColor: course.color}">
          <el-image class="user-course-wrapper__course-picture" :src="course.picture">
            <div slot="error">
              <img src="@/assets/img/user/no-courses.svg" alt="">
            </div>
          </el-image>
        </div>
        <div class="user-course-wrapper__main">
          <div class="user-course-wrapper__course-info">
            <div>
              <div class="user-course-wrapper__course-title">{{ course.name }}</div>
              <div class="user-course-wrapper__course-description">{{ course.description }}</div>
            </div>
              <div v-if="progressCourse > 99" class="user-course-wrapper__course-progress-text">
                Курс пройден успешно
                <i class="el-icon-circle-check"/>
              </div>
              <div v-else class="user-course-wrapper__course-progress-bar">
                <el-progress :percentage="progressCourse"/>
              </div>
          </div>

          <transition appear name="el-fade-in">
            <div v-if="course.certificateAvailable" class="user-course-wrapper__course-passed" @click.prevent="downloadCertificate">
              <div class="user-course-wrapper__course-passed-btn">
                <img class="user-course-wrapper__icon-btn-certificate" src="@/assets/img/user/profile/icon-btn-certificate.svg" alt="">
              </div>
              <div class="user-course-wrapper__course-passed-text">Скачать</div>
            </div>
            <div v-else class="user-course-wrapper__help">
              <div class="user-course-wrapper__help-text">Пройдите курс до конца и получите сертификат</div>
              <img class="user-course-wrapper__icon-certificate" src="@/assets/img/user/profile/icon-certificate.svg" alt="">
            </div>
          </transition>
        </div>
      </div>

    </router-link>
  </div>
</template>

<script>

import {coursesService} from "../../courses/courses.service";

export default {
  name: "UserCourse",
  props: {
    course: Object
  },
  data(){
    return {
      progressCourse: 0
    }
  },
  mounted() {
    setTimeout(()=>{this.progressCourse = Math.round(this.course.progress * 100)},200)
  },
  methods: {
    downloadCertificate() {
      coursesService.downloadCertificate(this.course.id)
    }
  }

}
</script>

<style lang="scss" scoped>
.user-course-wrapper {
  display: flex;
  align-items: center;

  margin-bottom: 16px;

  border: 1px solid #C4C4C4;
  box-sizing: border-box;
  border-radius: 20px;

  &__link {
    display: block;
    width: 100%;

    text-decoration: none;
  }

  &__course {
    display: flex;
    width: 100%;

    transition: 0.2s;

    &:hover {
      .user-course-wrapper__course-logo:before {
        transition: 400ms;
        bottom: 0;
        height: 100%;
      }
    }

    @media screen and (max-width: 700px) {
      height: 150px;
    }

    @media screen and (max-width: 500px) {
      height: max-content;
    }
  }

  &__main {
    display: flex;
    margin-left: 20px;
    margin-right: 40px;

    flex: 1;
    transition: 0.2s;

    @media screen and (max-width: 500px) {
      flex-direction: column;
      justify-content: space-between;
      margin-right: 20px;
    }
  }

  &__course-logo {
    position: relative;

    display: flex;
    align-items: center;

    width: 134px;
    height: 134px;

    border-radius: 20px;

    transition: 0.2s;

    &:before {
      content: '';
      position: absolute;
      bottom: 50%;
      left: 0;
      width: 100%;
      height: 0;
      background: rgba(255, 255, 255, 0.08);
      border-radius: 5px;
    }

    &:hover:before {
      transition: 400ms;
      bottom: 0;
      height: 100%;
    }

    @media screen and (max-width: 700px) {
      display: none;
    }
  }

  &__course-picture {
    padding: 16px;
    transition: 0.2s;
  }

  &__course-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;

    margin: 20px 0px;
    margin-right: 10px;

    transition: 0.2s;

    @media screen and (max-width: 500px) {
      margin-top: 14px;
      margin-bottom: 0px;
      margin-right: 0px;
    }
  }

  &__course-title {
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;

    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-word;
    overflow: hidden;

    color: #121212;

    transition: 0.2s;
    @media screen and (max-width: 500px) {
      font-size: 14px;
      line-height: 20px;
      -webkit-line-clamp: 2;
    }
  }

  &__course-description {
    font-family: Raleway;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #121212;

    margin-top: 4px;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-word;
    overflow: hidden;

    @media screen and (max-width: 500px) {
      font-size: 12px;
    }
  }

  &__course-progress-text {
    display: flex;
    align-items: center;

    font-family: Raleway;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #121212;

    i {
      color: #6ac035;
      margin-left: 8px;
    }
  }

  &__course-footer-delimiter {
    content: '';
    width: 4px;
    height: 4px;

    margin-left: 10px;
    margin-right: 10px;
    border-radius: 100%;
    background: #000000;
  }

  &__course-passed {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    width: 130px;

    margin: auto 0px;
    cursor: pointer;

    transition: 0.2s;

    @media screen and (max-width: 500px) {
      align-items: center;
      margin: 10px auto;
    }
  }
  &__course-passed-btn {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 48px;
    height: 48px;

    background: #407BFF;
    border-radius: 8px;

    transition: 0.2s;
    @media screen and (max-width: 500px) {
      width: 44px;
      height: 44px;
    }

    &:hover {
      background: #1454F2;
    }
  }
  &__course-passed-text {
    font-family: Raleway;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0em;
    text-align: center;
    color: #1454F2;

    margin-top: 6px;
  }

  &__help {
    width: 130px;
    margin-top: 20px;

    @media screen and (max-width: 500px) {
      width: max-content;
      margin-bottom: 14px;
      margin-top: 6px;
    }
  }

  &__help-text {
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;

    color: #121212;
    @media screen and (max-width: 500px) {
      display: inline-block;
      width: 170px;
    }
  }
}
</style>

<style lang="scss">
.user-course-wrapper {
  &__course-progress-bar {
    max-width: 420px;

    .el-progress-bar__outer {
      height: 12px !important;
    }

    .el-progress-bar__inner {
      background-color: #4EE1A3;
    }

    .el-progress__text {
      font-family: Montserrat;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      color: #121212;

      margin-left: 14px;
    }
  }

  &__course-picture {
    img {
      object-fit: contain;
      width: 104px;
      height: 104px;

      border-radius: 4px;
    }

    .el-image__inner {
      object-fit: contain;
      width: 100%;
      max-height: 104px;

      height: 100%;
      max-height: 104px;

      border-radius: 4px;
    }
  }
}
</style>